import React from 'react';
import '../styles/componentsStyle.css';
import {YMaps, Map, ZoomControl, Placemark} from '@pbe/react-yandex-maps';


function Contacts() {
  return (
    <section className={"contacts"}>
      <h1 className={"contact_title"}>Контакты</h1>
      <table className={'table_contacts'}>
          <tbody>
          <tr>
            <td>Запись по телефону:</td>
            <td>+7 (4942) 34-11-52</td>
          </tr>
          <tr>
            <td>Адрес:</td>
            <td>156016, г. Кострома, ул. Профсоюзная, 16</td>
          </tr>
        </tbody>
      </table>
      <YMaps>
        <div className='map'>
          <Map defaultState={{ center: [57.732390, 41.004063], zoom: 17 }} width={700} height={500}>
            <ZoomControl options={{ float: "right" }} />
            <Placemark defaultGeometry={[57.732390, 41.004063]} />
          </Map>
        </div>
      </YMaps>
    </section>
  );
}

export default Contacts;