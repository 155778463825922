import React, { useRef } from 'react';
import '../styles/header.css';
import '../styles/main.css';
import {Link, NavLink} from 'react-router-dom';
import Logo from '../content/logo3.png';
import Kebab from '../content/kebab.png';
import Close from '../content/close.png';
import Location from '../content/location_icon.png';
import Time from '../content/time_icon.png';
import Mobile from '../content/tel_icon.png';
import {useState, useEffect} from 'react'


function Header() {
  const rootRef = document.getElementById('root');
  const [headerStyle, setheaderStyle] = useState('');
  const [header_back, setheader_back] = useState('');
  const [webviewType, setWebviewType] = useState(rootRef?.getAttribute('type'));
  const [openKebab, setOpenKebab] = useState(false);
  window.addEventListener('scroll', function(e) {
    const posTop = (window.scrollY  !== undefined) ? window.scrollY  : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    setheaderStyle(posTop === 0 ? 'header' : 'header__fixed');
    setheader_back(posTop === 0 ? '0' : '199px');
  });
  function closeKebabFunc() {
    setOpenKebab(false);
  };
  function openKebabFunc() {
    setOpenKebab(true);
  };


useEffect(() => {
  const resize = () => {
    
    if(window.innerWidth <= 1330) {
      rootRef?.setAttribute('type', 'mobile');
      rootRef?.setAttribute('style', '--top-position-general: 10vh; --display-map-value: none');
      
    } else {
      rootRef?.setAttribute('type', 'desktop');
      rootRef?.setAttribute('style', '--top-position-general: 20vh; --display-map-value: block');
    }
    setWebviewType(rootRef?.getAttribute('type'));
}
  window.addEventListener('resize', resize)
}, [])


    return (
    <div>
      <div style={{height: header_back}}></div>
      {webviewType === 'desktop' ? (
      <section className={headerStyle}>
        <div className='header__top'>
          <div className='time__work'><img src={Time} className='icons'/>Пн-Сб 7:00-20:00 | Вс - Выходной</div>
          <div className='adress'><img src={Location} className='icons'/>г. Кострома, ул. Профсоюзная, 16</div>
          <div className='tel__number'><img src={Mobile} className='icons'/>+7 (4942) 34-11-52<br /> +7 915-920-32-44</div>
        </div>
        <div className='header__center'>
        <Link to="/" className='logo'>
          <img src={Logo} className='logo__img'/>
          <div className='logo__title'>Центр<br />Медицины и Красоты<br />Элитон</div>
        </Link>
          <NavLink to="/">Главная</NavLink>
          <NavLink to="/services">Услуги и цены</NavLink>
          <NavLink to="/about">О нас</NavLink>
          <NavLink to="/contacts">Контакты</NavLink>
          <button className='register__meet'>Запись на приём</button>
        </div>
      </section>
      ) : 
      (
        <section className={headerStyle}>
        <div className='header__center'>
        {openKebab ? 
          (
          <div >
            <img className='menu__kebab' src={Close} onClick={closeKebabFunc} />
            <div className='menu__kebab__list'>
              <NavLink id='navLink' onClick={closeKebabFunc} to="/">Главная</NavLink>
              <NavLink id='navLink' onClick={closeKebabFunc} to="/services">Услуги и цены</NavLink>
              <NavLink id='navLink' onClick={closeKebabFunc} to="/about">О нас</NavLink>
              <NavLink id='navLink' onClick={closeKebabFunc} to="/contacts">Контакты</NavLink>
              <div className='header__bottom'>
              <div className='adress'><img src={Location} className='icons'/>г. Кострома, ул. Профсоюзная, 16</div>
              <div className='time__work'><img src={Time} className='icons'/>Пн-Сб 7:00-20:00 | Вс - Выходной</div>
              <div className='tel__number'><img src={Mobile} className='icons'/>+7 (4942) 34-11-52<br /> +7 915-920-32-44</div>
            </div>
            </div>
          </div>
          )
          :
          (
            <img className='menu__kebab' src={Kebab} onClick={openKebabFunc} /> 
          )
           }
          <Link to="/" className='logo'>
            <img src={Logo} className='logo__img'/>
            <div className='logo__title'>Центр<br />Медицины и Красоты<br />Элитон</div>
          </Link>
        </div>
        <button className='register__meet__mobile'>Запись на приём</button>
      </section>
      )
      }
    </div>
    );
  }
  
  export default Header;