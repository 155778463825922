import React from 'react';
import '../styles/main.css';

function aboutUs() {
  return (
    <section className='aboutUs'>
        <div className='title_about'>О нас</div>
        <div className='text_about'>
          <p>Медицинский центр Элитон находится в уютном месте. Мы работаем с 2004 года. За это время тысячи наших клиентов изменили свою внешность и поправили свое здоровье в лучшую сторону, благодаря нашим специалистам. Все наши специалисты имеют профильное медицинское образование, большой стаж работы, отличное знание новейших методик, постоянно повышают свою квалификацию на семинарах, участвуют в профильных конференциях. Специалисты центра подбирают действительно эффективные комплексные программы преображения, направленные на омоложение, решение имеющихся эстетических проблем. Вы получите исчерпывающие консультации об интересующей вас процедуре и процессе лечения. Медицинский центр Элитон предоставляет более 50 услуг для улучшения вашего здоровья и восстановления энергии:</p>
          <li>эпиляция/депиляция</li>
          <li>10 видов массажа</li>
          <li>коррекция фигуры</li>
          <li>косметология</li>
          <li>удаление новообразований и сосудистых дефектов</li>
          <li>контурная пластика</li>
          <li>инъекционные процедуры</li>
          <li>СПА-программы</li>
          <p>Мы поможем вам избавиться от эстетических несовершенств с помощью аппаратных и инъекционных методик, массажа, пилинга, чисток. Мы используем только сертифицированную косметику, одноразовые расходные материалы, строго соблюдаем все этапы стерилизации инструментов. Центр оснащен современным, сертифицированным оборудованием от ведущих производителей. У медицинского центра имеется лицензия на осуществление медицинской деятельности № ЛО-44-01-000477, позволяющая оказывать инъекционные, аппаратные косметологические услуги.</p>
</div>
      </section>
  );
}

export default aboutUs;
