import React from 'react';
import {useEffect} from 'react'
import '../styles/main.css';
import '../styles/componentsStyle.css';
import defaultBd from '../default/dafault.json';
import axios from "axios";


function Services() {
  
  
  
  const callBackendAPI = async () => {
    try {
      const services = document.getElementById('services');
      const response = await axios.get('/api.json').catch(function (error) {
        console.log(error.response.status);
        return defaultBd;
      });
      const result = await response.data ? response.data : response;
      if(services.getAttribute("status")) return;
      result.map((data) => {
        const tr = document.createElement("tr");  
        tr.innerHTML =`<td>${data.name}</td><td >${data.price}р</td>`;
        services.appendChild(tr); 
      })
      services.setAttribute("status", "ready")
      console.log("ready");
      return services;

    } catch (e) {
      console.log("Ошибка",e);
    }
  };

  useEffect(() => {
    callBackendAPI();
  });


  return (
    <section className="services__block">
      <h1 className={"services_title"}>Услуги</h1>
      <table id={"services"}></table>
    </section>
  );
}

export default Services;
