import React from 'react';
import {Routes,Route, Link } from 'react-router-dom';
import Services from "../templates/services";
import AboutUs from "../templates/aboutUs";
import General from "../templates/General";
import Contacts from "../templates/contacts";

function Router() {

  return (
    <Routes>
        <Route index path="/" element={<General /> } />
        <Route path="*" element={<General /> } />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/api/services" element={''}/>
    </Routes>
  );
}

export default Router;
