import React from 'react';
import '../styles/main.css';
import '../styles/componentsStyle.css';
import { Link } from 'react-router-dom';

function General() {
  const rootRef = document.getElementById('root');
  const element = document.querySelector('.main-text-block');
  if(rootRef?.getAttribute('type') === 'mobile') {
    console.log('elem', element);
    //element.classList.add('main-text-block_mobile');
  }
  
  

  return (
    <section className = "main-logo">
      <div className='main-text-block'>
        <div className="intro_inner">
          <div className="main_suptitle">Центр Медицины и Красоты</div>
          <div className="main_title">ЭЛИТОН</div>
          <Link className="main-btn" to="/about">Подробнее</Link>
        </div>
      </div>
    </section>
  );
}

export default General;
